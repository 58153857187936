<template lang="">
    <div>
        <UsersDetails/>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
    
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kullanıcı Detay", route: "manage/user-details" },
    ]);
  },
    components:{
        UsersDetails: () => import('@/components/manage/users/UsersDetails.vue')

    }
}
</script>
<style lang="">
    
</style>